import {defineStore} from 'pinia';
import {UserInfo} from '../framework2-sdk/f2Api';
import config from '../config';
import {fetchUserByID,
    checkUserAndCompanyByEmail,
    updateUserPersonalProfile,
    toggleFavoriteSupplier,
    fetchProvinces,
    connectUserToCompany,
    fetchFoodPreferences, saveFoodPreferences} from '@/api/user';
import {updateUser} from '@/api/apiCommon';

import {Category} from './recommendedProducts';
import {Customer} from './customer';
import {Supplier} from './supplier';
import {SecureStoragePlugin} from 'capacitor-secure-storage-plugin';
import {CreateInvitationInput,
    createInvitation,
    fetchCompanyMembersAndInvitations,
    fetchFavoriteProducts,
    addProductToFavorites,
    removeProductFromFavorites} from '@/api/user';
import {Product} from './product';
export interface UserState {
  user: null | UserInfo;
  userAdditionalData: null | User;
  pushNotificationToken: null | { token: string; id: number };
  showInviteModal: boolean;
  showCreateBusinessModal: boolean;
  showProfileViewSettingsModal: boolean;
  favoriteProducts: Product[];
  provinces: { Province: string; ID: number }[];
  likedFoods: Category[];
  dislikedFoods: Category[];
  hasCompletedOnboarding: boolean;
}

export interface User {
    ID:string;
    F2_ID:number;
    name: string;
    surname: string;
    email: string;
    isAdmin:boolean;
    currentCompany : Customer|Supplier
    favoriteSuppliers: Supplier []
}

export interface Invitation {
    ID: string;
    createdAt: Date;
    usedAt?: Date;
    name: string;
    surname: string;
    email: string;
    status: 'PENDING' | 'ACCEPTED' | 'EXPIRED';
}

export interface Credentials {
    username: string;
    password: string;
    applicationKey?: string;
}

export const useUserStore = defineStore({
    id: 'user',
    state: () =>
        ({
            user: null,
            userAdditionalData: null,
            pushNotificationToken: null,
            showInviteModal: false,
            showCreateBusinessModal: false,
            showProfileViewSettingsModal: false,
            openedProfileSettingsFromPosting: false,
            favoriteProducts: [] as Product[],
            provinces: [],
            likedFoods: [],
            dislikedFoods: [],
            hasCompletedOnboarding: false,
        } as UserState),
    actions: {
        async fetchProvinces() {
            const fetchedProvinces = await fetchProvinces();
            this.provinces = fetchedProvinces;
        },
        initUser(userInfo: UserInfo) {
            // Check if the user is a restaurant or supplier user before initializing
            const isRestaurantUser =
        userInfo.roleSettings[0]?.uid === config.restaurantRoleUid;
            const isSupplierUser =
        userInfo.roleSettings[0]?.uid === config.supplierRoleUid;
            if (isRestaurantUser || isSupplierUser) {
                this.user = userInfo;
            } else {
                throw new Error('Entrare dalla piattaforma web');
            }
        },
        async loadFavoriteProducts() {
            if (this.userAdditionalData) {
                const filter = {ID: this.userAdditionalData.ID};
                const products = await fetchFavoriteProducts(filter);
                this.favoriteProducts = products; // This should be correct
            }
        },

        async retreiveUserData() {
            const additionalUserData = await fetchUserByID(this.user?.id);
            this.addUserAdminProperty(additionalUserData);
            this.userAdditionalData = additionalUserData;
        },
        addUserAdminProperty(user: any) {
            const isAdmin =
        user.currentCompanyConnection?.edges?.length > 0 ?
            user.currentCompanyConnection.edges[0].admin :
            false;
            user.isAdmin = isAdmin;
        },
        async checkUserCompanyByEmail(email: string) {
            return await checkUserAndCompanyByEmail(email);
        },
        async loadFoodPreferences() {
            const preferences = await fetchFoodPreferences();
            this.likedFoods = preferences.likedFoods;
            this.dislikedFoods = preferences.dislikedFoods;
            this.hasCompletedOnboarding = preferences.hasCompletedOnboarding;
        },

        updateFoodPreferences(liked: Category[], disliked: Category[]) {
            this.likedFoods = liked;
            this.dislikedFoods = disliked;
        },

        async saveFoodPreferences(liked: Category[], disliked: Category[]) {
            const success = await saveFoodPreferences(liked, disliked);
            if (success) {
                this.likedFoods = liked;
                this.dislikedFoods = disliked;
                this.hasCompletedOnboarding = true;
            }
        },

        async readPushNotificationToken() {
            try {
                const {value} = await SecureStoragePlugin.get({
                    key: `pushNotificationToken-${this.user?.id}`,
                });
                this.pushNotificationToken = JSON.parse(value);
            } catch (e) {
                /* */
            }
        },
        async setPushNotificationToken(pushNotificationToken: {
      token: string;
      id: number;
    }) {
            await SecureStoragePlugin.set({
                key: `pushNotificationToken-${this.user?.id}`,
                value: JSON.stringify(pushNotificationToken),
            });
            this.pushNotificationToken = pushNotificationToken;
        },
        async deletePushNotificationToken() {
            await SecureStoragePlugin.remove({
                key: `pushNotificationToken-${this.user?.id}`,
            });
            this.pushNotificationToken = null;
        },
        updateCompany(company: Supplier | Customer) {
            if (this.userAdditionalData && this.userAdditionalData.currentCompany) {
                this.userAdditionalData.currentCompany.Description =
          company.Description;
                this.userAdditionalData.currentCompany.profileImage =
          company.profileImage;
            } else {
                console.error(
                    'Failed to update the company description. Data is missing.',
                );
            }
        },
        async updateAndSetUser(
            userData: Partial<User> & { password?: string; oldPassword?: string },
        ) {
            // Update user profile
            const updatedUser = await updateUserPersonalProfile(
                this.user?.id,
                userData,
            );
            if (updatedUser) {
                this.userAdditionalData = updatedUser;
            }

            // Check if email has changed or new password is set
            if (
                userData.email !== this.userAdditionalData?.email ||
        userData.password
            ) {
                if (!userData.oldPassword) {
                    throw new Error(
                        'Password attuale richiesta per modificare email o password',
                    );
                }

                // Call updateUser for email/password changes
                if (this.userAdditionalData) {
                    const updateData: any = {
                        id: this.userAdditionalData.F2_ID,
                        oldPassword: userData.oldPassword,
                    };

                    if (userData.email !== this.userAdditionalData.email) {
                        updateData.email = userData.email;
                    }

                    if (userData.password) {
                        updateData.password = userData.password;
                    }

                    // Call the updateUser function (you need to import this function)
                    const updatedUserData = await updateUser(updateData);
                    this.userAdditionalData = {
                        ...this.userAdditionalData,
                        ...updatedUserData,
                    };
                }
            }
        },
        async createInvitation(input: CreateInvitationInput) {
            await createInvitation(input);
            // console.log(invitation);
        },

        async toggleFavoriteProduct(productId: string, isFavorite: boolean) {
            if (!this.userAdditionalData) {
                console.error('User data is not loaded');
                return;
            }

            if (!isFavorite) {
                await removeProductFromFavorites(this.userAdditionalData.ID, productId);
                // Update local state to reflect the change
            } else {
                const newFavorite = await addProductToFavorites(
                    this.userAdditionalData.ID,
                    productId,
                );
                if (newFavorite) {
                    this.favoriteProducts.push(newFavorite);
                }
            }
        },

        async toggleFavoriteSupplier(supplierUID: string) {
            if (this.userAdditionalData) {
                const isCurrentlyFavorite =
          this.userAdditionalData.favoriteSuppliers.some(
              (supplier) => supplier.uid === supplierUID,
          );

                const updatedUser = await toggleFavoriteSupplier(
                    this.userAdditionalData.ID,
                    supplierUID,
                    isCurrentlyFavorite,
                );
                if (updatedUser) {
                    // e.g., this.userAdditionalData = updatedUser;
                }
            }
        },
        async fetchCompanyMembersAndInvitations() {
            try {
                const companyType = this.userAdditionalData?.currentCompany.__typename;
                const companyUID = this.userAdditionalData?.currentCompany.uid;
                const {members, invitations} =
          await fetchCompanyMembersAndInvitations(companyType, companyUID);
                if (this.userAdditionalData && this.userAdditionalData.currentCompany) {
                    this.userAdditionalData.currentCompany.users = members;
                    this.userAdditionalData.currentCompany.invitations = invitations;
                    this.userAdditionalData.currentCompany.members = [
                        ...members.map((member) => ({
                            ...member,
                            type: 'Member',
                        })),
                        ...invitations.map((invitation) => ({
                            ...invitation,
                            type: 'Invitation',
                        })),
                    ];
                    return this.userAdditionalData.currentCompany.members;
                }
            } catch (err) {
                console.error(err);
                return [];
            }
        },
        async connectUserToCompany(email: string, companyUID: string) {
            const result = await connectUserToCompany(email, companyUID);
            return result;
        },
        toggleInviteModal(show: boolean) {
            this.showInviteModal = show;
        },
        toggleProfileViewSettingsModal(show: boolean) {
            this.showProfileViewSettingsModal = show;
        },
        async toggleCreateBusinessModal(show: boolean) {
            this.showCreateBusinessModal = show;
        },
        resetUserStore() {
            this.$reset();
        },
    },
    getters: {
        isRestaurantUser(state) {
            return state.user?.roleSettings[0]?.uid === config.restaurantRoleUid;
        },
        isSupplierUser(state) {
            return state.user?.roleSettings[0]?.uid === config.supplierRoleUid;
        },
        hasCurrentCompany(state) {
            return state.userAdditionalData?.currentCompany !== null;
        },
    },
});
