export default {
    // f2BaseURL: 'http://192.168.1.17:3000',
    f2BaseURL: 'https://fedrogustoautentico.framework2.dev',
    mobileAppUrl: 'https://fedromobile.framework2.dev',
    filesApi: '/app-key/fedro/api/files/',
    graphqlURL: '/api/graphql',
    unAuthedGraphqlURL: '/public/graphql',
    applicationKey: 'fedro',
    restaurantRoleUid: '0001687845225058-XTNu04bqd7',
    supplierRoleUid: '0001688545441887-mc9Ej5exW6',
    development: false,
    privacyLink: 'https://www.iubenda.com/termini-e-condizioni/21988569',
    appStoreUrl: 'https://apps.apple.com/it/app/casa-fedro/id6657978107',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=it.fedrogustoautentico.userapp',
};
